<template>
  <div class="body" @scroll="handleScroll" v-if="detailData && detailData.meta">
    <header>
      <!-- <AdPage></AdPage> -->
      <h2 class="title">
        {{ this.detailData.title }}
      </h2>
      <div class="gray">
        <span>{{ this.detailData.meta.publisher }}</span>
        <span id="publishTime">{{ timeAgo }}</span>
      </div>
    </header>
    <!-- <AdPage></AdPage> -->
    <main id="article-content">
      <div id="op_container_text">
        <div class="banner">
          <img :src="detailData.meta.image_url" alt="thumbnail" class="thumbnail" />
        </div>
        <div class="summary" ref="content"></div>
        <div class="expand">
          <a class="expand-btn expand-default" id="expand" :href="detailData.meta.url">
            <span>{{ language.continueReading }}</span>
            <img class="ltr" src="@/assets/arrowright.png" />
          </a>
        </div>
      </div>
    </main>
    <!-- <AdPage></AdPage> -->
    <div class="open-related-news">
      <div class="related-news-list">
        <div class="list-title">{{ language.recommendedForYou }}</div>
      </div>

      <!-- <template v-for="(group, groupIndex) in groupedNews"> -->
      <template v-for="group in groupedNews">
        <div v-for="item in group" :key="item.id" class="related-news-item">
          <RelatedNews :RelatedNewsData="item" />
        </div>
        <!-- <AdPage v-if="groupIndex < groupedNews.length - 1" :key="'ad-' + groupIndex" /> -->
      </template>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import RelatedNews from '@/components/RelatedNews.vue';
// import AdPage from '@/components/AdPage';
import util from '@/assets/util.js';
export default {
  name: 'NewsView',
  components: {
    RelatedNews,
    // AdPage,
  },
  data() {
    return {
      relatedNewsData: [], //推荐列表数据
      detailData: {}, //主文数据
      percentageRead: 0, //阅读完成率
      startTime: 0, //开始阅读时间
      content_html: '', // 主文html
    };
  },
  methods: {
    //获取 feed 详情 并且初始化页面数据
    async getFeedDetailData() {
      const fid = this.$route.query.fid || 'dd295fcbc8ba695920ec51c3fb553c8c'; //从URL拿到fid
      const country = this.$route.query.country || 'gb'; //从URL拿到fid
      const client_info = this.$route.query.client_info || 'ABC'; //从URL拿到fid
      const os_ver = this.$route.query.os_ver || '9.0'; //从URL拿到fid
      const publish_time = this.$route.query.publish_time || '1721560110'; //从URL拿到fid
      const channel = this.$route.query.channel || 'm'; //从URL拿到fid
      const language = this.$route.query.language || 'en'; //从URL拿到fid
      const model = this.$route.query.model || 'oppo'; //从URL拿到fid
      const category = this.$route.query.category || 'Society'; //从URL拿到fid

      try {
        let { data } = await this.$api.getFeedDetail(fid, country, client_info, client_info, os_ver, publish_time, channel, language, model, category);
        this.detailData = data;
        if (data.meta) {
          this.detailData.meta = JSON.parse(data.meta);
        } else {
          this.detailData.meta = {};
        }
        // console.log(this.detailData);
        this.content_html = util.extractAndFormatPTags(this.detailData.contentxml, this.detailData.content);
        this.content_html = this.handle_content(this.content_html);
        this.$nextTick(() => {
          // console.log(this.$refs.content);
          this.$refs.content.innerHTML = this.content_html;
          // console.log('this.content_html', this.content_html);
        });

        this.relatedNewsData = data.recList.map((item) => {
          item.meta = JSON.parse(item.meta);
          return item;
        });

        document.title = this.detailData.title;
        // console.log(this.detailData);
      } catch (error) {
        // console.log(error);
      }
    },
    // 处理滚动事件
    handleScroll() {
      this.percentageRead = util.handleScroll(this.percentageRead);
      // return util.handleScroll(this.percentageRead)
    },
    // 点击链接事件————发送点击统计
    handleLinkClick(event) {
      let targetElement = event.target;
      event.preventDefault(); // 取消默认点击行为
      while (targetElement && targetElement !== document.getElementById('app')) {
        // console.log('targetElement------------->>>>>', targetElement);
        if (targetElement.tagName === 'A' && !targetElement.className.includes('news-item')) {
          console.log('get');
          const id = targetElement.getAttribute('id');
          this.sendClickToGA(id);
          window.location.href = targetElement.href;
          break;
        }
        targetElement = targetElement.parentElement;
      }
    },
    // 发送阅读完成和时长统计
    sendToGA() {
      // gtag('event', 'read_complete', {
      //   news_id: this.detailData.fid,
      //   percentage: this.percentageRead + '%',
      // });
      // console.log("阅读完成" + this.percentageRead) + '%';
      // console.log('startTime', this.startTime);
      const endTime = new Date().getTime();
      // console.log('endTime', endTime);
      const durationInSeconds = Math.floor((endTime - this.startTime) / 1000);
      durationInSeconds;
      // console.log('阅读时长：' + durationInSeconds + 's');
      // gtag('event', 'read_duration', {
      //   news_id: this.detailData.fid,
      //   duration: durationInSeconds,
      // });
    },
    // 发送展示统计
    sendViewToGA() {
      // gtag('event', 'view_news', {
      //   news_id: this.detailData.fid,
      //   category: this.detailData.category,
      // });
    },
    // 发送点击打点
    sendClickToGA(id) {
      id;
      // gtag('event', 'click_news', {
      //   news_id: id,
      //   category: this.detailData.category,
      // });
    },
    // 处理内容
    handle_content(text) {
      return util.removeContinueReading(text);
    },

    // 元素出现在可视窗口触发事件
    handleIntersection(entries, observer) {
      entries.forEach(async (entry) => {
        if (entry.isIntersecting) {
          // 当推荐列表出现在可视窗口时，发送后端展示统计请求
          const url = entry.target.getAttribute('impression_tracking_url');
          try {
            await this.$api.show(url);
          } catch (error) {
            console.warn(error);
          }
          observer.unobserve(entry.target); // 取消对该元素的观察
        }
      });
    },
    observeElements() {
      const observer = new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.0, // 元素在可视窗口中时触发
      });
      const elements = document.querySelectorAll('.news-item'); // 获取所有推荐列表元素
      elements.forEach((element) => {
        // 监听元素的可见性
        observer.observe(element);
        // 添加点击事件监听器
        element.addEventListener('click', async (event) => {
          event.preventDefault(); // 取消默认点击行为

          // 发送后端点击统计
          const url = element.getAttribute('click_tracking_url');
          const { code } = await this.$api.show(url);
          if (code === 0) {
            //当
            this.sendClickToGA(element.getAttribute('id'));
            const href = element.getAttribute('href');
            window.location.href = href;
          }
        });
      });
    },
  },
  computed: {
    //发布时间差
    timeAgo() {
      return this.detailData.meta.pub_date ? util.timeDifference(this.detailData.meta.language, this.detailData.meta.pub_date) : '';
    },

    // 语种转换
    language() {
      return util.translateLabels(this.detailData.meta.language);
    },

    // 广告位置间隔
    groupedNews() {
      const groups = [];
      for (let i = 0; i < this.relatedNewsData.length; i += 3) {
        groups.push(this.relatedNewsData.slice(i, i + 3));
      }
      return groups;
    },
  },
  mounted() {
    // 页面初始化
    this.startTime = new Date().getTime();
    this.getFeedDetailData();

    // 发送展示统计
    this.sendViewToGA();

    // 页面滚动事件监听
    window.addEventListener('scroll', this.handleScroll);
    // 页面关闭和刷新事件监听
    window.addEventListener('beforeunload', this.sendToGA);
    // 使用事件委托监听外层元素的点击事件
    document.getElementById('app').addEventListener('click', this.handleLinkClick);
  },

  updated() {
    // 确保页面DOM都渲染完成后再执行，避免获取不到元素

    // 监听元素的可见性
    this.$nextTick(() => {
      this.observeElements();
    });
  },
  beforeDestroy() {
    // 页面滚动事件移除监听器
    window.removeEventListener('scroll', this.handleScroll);
    // 移除链接点击事件监听器
    document.getElementById('app').removeEventListener('click', this.handleLinkClick);
  },
};
</script>

<style lang="less">
.body {
  font-family: 'Poppins', sans-serif;

  .gray {
    display: flex;
    justify-content: space-between;

    span {
      color: rgba(0, 0, 0, 0.6);
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
  }

  #op_container_text {
    font-size: 16px;
    margin-bottom: 2px;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px;

    .banner {
      overflow: hidden;
      padding: 10px;
      box-sizing: border-box;

      .thumbnail {
        display: block;
        object-fit: cover;
        width: 100%;
        border-radius: 10px;
      }
    }

    .summary {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      // white-space: pre-line;
      line-height: 24px;
      padding: 0 10px;
      box-sizing: border-box;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      /* 限制显示的行数 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .expand {
      display: flex;
      justify-content: flex-end;
      overflow: hidden;

      .expand-btn {
        background-color: #fff;
        align-items: center;
        border-radius: 2px;
        border: none;
        display: flex;
        height: 26px;
        outline: none;
        padding: 1px 6px;
        text-align: center;

        span {
          color: #5bc0de;
          font-weight: 500;
        }

        img {
          height: 16px;
          width: 16px;
        }

        img.rtl {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>
