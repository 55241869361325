// 发布时间差计算函数
const timeDifference = (language, date) => {
  // 将时间字符串转换为日期对象
  const eventDate = new Date(date);
  // console.log(this.detailData.meta.pub_date);
  // 获取当前本地时间的日期对象
  const now = new Date();

  // 计算时间差（以毫秒为单位）
  const timeDiff = now - eventDate;
  // console.log('now', now);
  // console.log('eventDate', eventDate);

  // 将时间差转换为秒、分钟、小时、天、周、月、年
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // 近似一个月为30天
  const years = Math.floor(days / 365); // 近似一年为365天
  // console.log('年月日分秒：----》', timeDiff, years, months, weeks, days, hours, minutes, seconds);

  function getTimeAgo({ years, months, weeks, days, hours, minutes, seconds }, language) {
    const timeUnits = {
      de: [
        { value: years, unit: 'Jahr(e)' },
        { value: months, unit: 'Monat(e)' },
        { value: weeks, unit: 'Woche(n)' },
        { value: days, unit: 'Tag(e)' },
        { value: hours, unit: 'Stunde(n)' },
        { value: minutes, unit: 'Minute(n)' },
        { value: seconds, unit: 'Sekunde(n)' },
      ],
      en: [
        { value: years, unit: 'year(s)' },
        { value: months, unit: 'month(s)' },
        { value: weeks, unit: 'week(s)' },
        { value: days, unit: 'day(s)' },
        { value: hours, unit: 'hour(s)' },
        { value: minutes, unit: 'minute(s)' },
        { value: seconds, unit: 'second(s)' },
      ],
      es: [
        { value: years, unit: 'año(s)' },
        { value: months, unit: 'mes(es)' },
        { value: weeks, unit: 'semana(s)' },
        { value: days, unit: 'día(s)' },
        { value: hours, unit: 'hora(s)' },
        { value: minutes, unit: 'minuto(s)' },
        { value: seconds, unit: 'segundo(s)' },
      ],
    };

    const units = timeUnits[language];

    for (const { value, unit } of units) {
      if (value > 0) {
        switch (language) {
          case 'de':
            return `${value} ${unit} her`;
          case 'en':
            return `${value} ${unit} ago`;
          case 'es':
            return `${value} ${unit} atrás`;
        }
      }
    }
  }
  return getTimeAgo({ years, months, weeks, days, hours, minutes, seconds }, language);
};

// 语种转换;
const translateLabels = (language) => {
  const labels = {
    en: {
      continueReading: 'Continue Reading',
      recommendedForYou: 'Recommended for You',
    },
    es: {
      continueReading: 'Sigue Leyendo',
      recommendedForYou: 'Recomendado para ti',
    },
    de: {
      continueReading: 'Weiterlesen',
      recommendedForYou: 'Empfohlen für Sie',
    },
  };

  return labels[language] || labels['en'];
};

// 计算阅读完成百分比函数
const handleScroll = (percentageRead) => {
  if (percentageRead < 100) {
    const format = (num) => Math.round(Math.abs(num));
    const content = document.getElementById('article-content');
    const rect = content.getBoundingClientRect();
    const top = rect.top;
    const bottom = rect.bottom;
    const c = Math.max(document.documentElement.clientHeight, window.innerHeight);
    const t = format(top);
    const b = format(bottom);
    if (bottom > 0) {
      if (top > 0) {
        percentageRead = Math.ceil((c / b) * 100);
      } else {
        percentageRead = Math.ceil(((t + c) / (t + b)) * 100);
      }
    } else {
      percentageRead = Math.ceil(((t + c) / (t - b)) * 100);
    }
    // console.log(percentageRead + '%');
  } else {
    percentageRead = 100;
    // console.log(percentageRead + '%');
  }
  return percentageRead;
};

// 内容删除继续阅读处理
const removeContinueReading = (text) => {
  const phrases = [
    'Continue Reading', // English
    'Sigue leyendo', // Spanish
    'Weiterlesen', // German
  ];

  phrases.forEach((phrase) => {
    const regex = new RegExp(phrase, 'gi');
    text = text.replace(regex, '');
  });

  return text;
};

// content 替换样式 如：添加加粗
function extractAndFormatPTags(htmlString, textContent) {
  // 创建一个 DOM 解析器
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // 要屏蔽的句子列表
  const blacklist = [
    'Sign up for our free Indy100 weekly newsletter',
    "How to join the indy100's free WhatsApp channel",
    'Have your say in our news democracy. Click the upvote icon at the top of the page to help raise this article through the indy100 rankings',
  ];

  // 移除黑名单中的内容
  blacklist.forEach((phrase) => {
    textContent = textContent.replace(new RegExp(phrase, 'g'), '');
  });

  // 获取所有的 <figure> 标签
  const figureTags = doc.querySelectorAll('figure');
  figureTags.forEach((figure) => {
    // 获取 <figcaption> 标签中的文本内容
    const figcaption = figure.querySelector('figcaption');
    if (figcaption) {
      const figureText = figcaption.textContent.replace(/\s+/g, ' ').trim();
      textContent = textContent.replace(figureText, '');
    }
  });

  // 函数：递归移除子节点中的标签，仅保留文本内容
  function removeInnerTags(node) {
    const childNodes = Array.from(node.childNodes);
    childNodes.forEach((child) => {
      if (child.nodeType === Node.ELEMENT_NODE) {
        removeInnerTags(child); // 递归处理子节点
        const textContent = child.textContent;
        child.replaceWith(textContent);
      }
    });
  }

  // 函数：获取标签内的纯文本内容
  function getTextContent(node) {
    return node.textContent.replace(/\s+/g, ' ').trim();
  }

  // 获取所有 <p> 标签的文本内容，并保留 <strong> 和 <em> 标签的结构
  const formattedPTags = Array.from(doc.querySelectorAll('p')).map((p) => {
    const cloneP = p.cloneNode(true);
    const childNodes = Array.from(cloneP.childNodes);

    childNodes.forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        if (node.nodeName === 'STRONG' || node.nodeName === 'EM') {
          removeInnerTags(node);
        } else {
          const textContent = node.textContent;
          node.replaceWith(textContent);
        }
      }
    });

    return {
      tag: 'p',
      content: getTextContent(cloneP),
    };
  });

  // 构建新的 HTML 结构
  let resultHTML = textContent;

  formattedPTags.forEach((pTag) => {
    if (resultHTML.includes(pTag.content)) {
      resultHTML = resultHTML.replace(pTag.content, `<p>${pTag.content}</p>`);
    }
  });

  // 临时容器，用于进一步处理 <strong> 和 <em> 标签
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = resultHTML;

  const elementsToWrap = ['strong', 'em'];

  elementsToWrap.forEach((tag) => {
    const nodes = tempDiv.querySelectorAll(tag);
    nodes.forEach((node) => {
      const content = getTextContent(node);
      if (resultHTML.includes(content)) {
        resultHTML = resultHTML.replace(content, `<${tag}>${content}</${tag}>`);
      }
    });
  });

  // 处理 <strong> 和 <em> 标签中的嵌套标签
  elementsToWrap.forEach((tag) => {
    const nodes = tempDiv.querySelectorAll(tag);
    nodes.forEach((node) => {
      removeInnerTags(node);
    });
  });

  return tempDiv.innerHTML;
}
export default {
  timeDifference,
  handleScroll,
  translateLabels,
  removeContinueReading,
  extractAndFormatPTags,
};
