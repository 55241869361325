import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api/index.js"; //可以通过this.$api.xxx()调用`/src/http/index.js`中的xxx方法。
// import Ads from "vue-google-adsense";
import Adsense from 'vue-google-adsense/dist/Adsense.min.js'


Vue.config.productionTip = false;
Vue.prototype.$api = api;

Vue.use(require('vue-script2'))
Vue.use(Adsense)

// Vue.use(Ads.Adsense);
// Vue.use(Ads.InArticleAdsense);
// Vue.use(Ads.InFeedAdsense);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

