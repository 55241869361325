import Vue from 'vue';
import VueRouter from 'vue-router';
import NewsView from '../views/NewsView.vue';
import TestView from '../views/TestView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'News',
    component: NewsView,
  },
  {
    path: '/test',
    name: 'Test',
    component: TestView,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
export default router;
