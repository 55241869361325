<!-- <template v-for="item in RelatedNewsData"> -->
  <template>
  <!-- <template > -->

  <a class="news-item" target="_parent" :href="RelatedNewsData.url" :id="RelatedNewsData.fid" :click_tracking_url="RelatedNewsData.click_tracking_url" :impression_tracking_url="RelatedNewsData.impression_tracking_url">
    <div class="thumbnail">
      <img :src="RelatedNewsData.meta.image_url" alt="" />
    </div>
    <div class="main">
      <div class="big">
        {{ RelatedNewsData.title }}
      </div>
      <div class="extra">
        <i :style="getBackgroundImageStyle(RelatedNewsData.meta.publisher_icon)"></i>
        <span>{{ RelatedNewsData.meta.publisher }}</span>
      </div>
    </div>
    <!-- <AdPage v-if="(index + 1) % 4 === 0" :key="item.id" /> -->
  </a>
</template>
<script>
// import { RouterLink } from 'vue-router';
// import AdPage from "@/components/AdPage.vue";
export default {
  name: 'RelatedNews',
  props: {
    RelatedNewsData: Object,
  },
  data() {
    return {};
  },
  methods: {
    getBackgroundImageStyle(iconUrl) {
      return {
        'background-image': `url(${iconUrl})`,
      };
    },
    // handleClick() {
    //     this.$nextTick(() => {
    //         window.location.reload();
    //         window.scrollTo(0, 0);
    //     });
    // }
  },
  components: {
    // AdPage,
    // RouterLink,
  },

  mounted() {
    // console.log('RelatedNews mounted',this.RelatedNewsData);
    // console.log(this.RelatedNewsData);
    // :click_tracking_url="item.click_tracking_url" :impression_tracking_url="item.impression_tracking_url"
  },
  // computed: {
  //   url(){
  //     return this.RelatedNewsData.url.replace('https://www.imelynews.com/', 'https://mi.newsinformi.com/');
  //   }
  // }
};
</script>

<style lang="less">
.open-related-news {
  .related-news-list {
    &:before {
      background-color: rgba(0, 0, 0, 0.05);
      content: '';
      display: block;
      height: 2px;
      margin-bottom: 2px;
    }

    .list-title {
      align-items: center;
      display: flex;
      padding: 0 var(--base-padding);
      margin: 20px 0px;

      &:before {
        background: #b92030;
        border-radius: 22px;
        content: '';
        height: 10px;
        margin-inline-end: 4px;
        width: 2px;
      }
    }
  }

  .news-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 15px 5px;
    cursor: pointer;
    .thumbnail {
      background-color: rgba(0, 0, 0, 0.1);
      margin-inline-end: 16px;
      background-size: cover;
      overflow: hidden;
      height: 101px;
      width: 134px;
      border-radius: 10px;

      img {
        border-radius: 10px;
        max-width: 100%;
        min-height: 100%;
      }
    }

    .main {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      width: 0;
    }

    .big {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: #000;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      text-overflow: ellipsis;
      word-break: break-word;
    }

    .extra {
      display: flex;
      align-items: center;

      i {
        background-color: rgba(0, 0, 0, 0.1);
        background-size: cover;
        margin-inline-end: 4px;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        display: block;
      }

      span {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 15px;
      }
    }
  }
}
</style>
