import http from './http.js';
// // 获取feed详情
function getFeedDetail(id, country,client_info,os_ver,publish_time,channel,language,mocel,category) {
  return http.get(`/detail?fid=${id}&country=${country}&client_info=${client_info}&os_ver=${os_ver}&publish_time=${publish_time}&channel=${channel}&language=${language}&mocel=${mocel}&category=${category}`);
}
// 获取feed列表
function getFeedList(category) {
  return http.get(`/list?category=${category}`);
}

//测试用 
function testApi(token, count, client_info, user_ip, model, os_ver, language, country) {
  return http.get(`/m/list?token=${token}&count=${count}&client_info=${client_info}&user_ip=${user_ip}&model=${model}&os_version=${os_ver}&language=${language}&country=${country}`);
  // https://api.imelynews.com/feed/m/list?
}

function show(url) {
  url = url.replace('https://api.imelynews.com/feed', '');
  return http.get(`${url}`);
}

export default {
  getFeedList,
  getFeedDetail,
  testApi,
  show,
};
