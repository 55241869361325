import axios from 'axios';

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://api.imelynews.com/feed' : '/feed';
axios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axios;
