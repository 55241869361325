<template>
  <div class="box">
    <a v-for="(item, index) in list" :key="index" @click.prevent="click_fun" class="item" :click_tracking_url="item.click_tracking_url" :impression_tracking_url="item.impression_tracking_url">
      <span class="title">{{ item.title }}</span>
      <span>分类:{{ item.category }}</span>
      <span>CTR:{{ item.ctr }}</span>

      <span>时间:{{ checkTimestampWithinThreeDays(item.publish_time).status }} 日期：{{ checkTimestampWithinThreeDays(item.publish_time).timePoint }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'TestView',
  data() {
    return {
      list: [],
      token: '440e8499',
      count: 100,
      client_info: 'wdwdwd',
      user_ip: '192.168.127.12',
      mdoel: 'oppo',
      os_version: '9.0',
      language: 'de',
      country: 'de',

      // https://api.imelynews.com/feed/m/list?token=440e8499&count=10&client_info=abcc&user_ip=192.168.127.12&model=oppo&os_version=9.0&language=es&country=es
      //       英国
      // 国家简称：GB（或 UK）
      // 语言简称：en（英语）
      // 德国
      // 国家简称：DE
      // 语言简称：de（德语）
      // 西班牙
      // 国家简称：ES
      // 语言简称：es（西班牙语）
    };
  },
  methods: {
    async fetchData() {
      let { data } = await this.$api.testApi(this.token, this.count, this.client_info, this.user_ip, this.mdoel, this.os_version, this.language, this.country);
      this.list.push(...data.list);
      data.list.forEach((item) => {
        item.impression_tracking_url = item.impression_tracking_url.replace('https://api.imelynews.com/feed', '');
        // this.$api.show(item.impression_tracking_url);
      });

      const categoryCount = data.list.reduce((acc, item) => {
        acc[item.category] = (acc[item.category] || 0) + 1;
        return acc;
      }, {});

      // 计算总数
      const totalCount = data.list.length;

      // 将次数转换为百分比
      const categoryPercentage = [];
      for (let key in categoryCount) {
        const percentage = ((categoryCount[key] / totalCount) * 100).toFixed(2);
        categoryPercentage.push({ category: key, percentage: parseFloat(percentage) });
      }
      categoryPercentage.sort((a, b) => b.percentage - a.percentage);
      console.log(totalCount);
      console.log(categoryPercentage);
    },
    click_fun(e) {
      const closestParentA = this.findClosestAParent(e.target);
      let click_tracking_url = closestParentA.getAttribute('click_tracking_url');
      this.$api.show(click_tracking_url);
    },
    checkTimestampWithinThreeDays(timestamp) {
      timestamp = timestamp * 1000;
      // 当前时间的时间戳
      const now = new Date().getTime();

      // 三天的毫秒数
      const threeDaysInMillis = 3 * 24 * 60 * 60 * 1000;

      // 计算时间差
      const timeDifference = now - timestamp;

      // 格式化日期和时间点
      const date = new Date(timestamp);
      const formattedDate =
        date.getFullYear() +
        '-' +
        String(date.getMonth() + 1).padStart(2, '0') +
        '-' +
        String(date.getDate()).padStart(2, '0') +
        ' ' +
        String(date.getHours()).padStart(2, '0') +
        ':' +
        String(date.getMinutes()).padStart(2, '0');

      // 判断是否在三天内
      if (timeDifference <= threeDaysInMillis) {
        return {
          status: '三天内',
          timePoint: formattedDate,
        };
      } else {
        return {
          status: '三天外',
          timePoint: formattedDate,
        };
      }
    },
    findClosestAParent(element) {
      // 如果当前元素不存在或者已经到达了 document，返回 null
      if (!element || element === document) {
        return null;
      }
      // 如果当前元素是 <a> 标签，返回这个元素
      if (element.tagName.toLowerCase() === 'a') {
        return element;
      }

      // 否则递归调用，查找父元素
      return this.findClosestAParent(element.parentNode);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="less" scoped>
.box {
  .item {
    display: flex;
    line-height: 50px;
    cursor: pointer;
    justify-content: space-between;
    .title {
      width: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
/* 样式代码 */
</style>
