import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    adData: [
      { div: `<div id='div-gpt-ad-1718690001388-0' style='min-width: 300px; min-height: 250px;'></div>` },
      { div: `<div id='div-gpt-ad-1718606419299-0' style='min-width: 300px; min-height: 250px;'></div>` },
      { div: `<div id='div-gpt-ad-1718606457232-0' style='min-width: 300px; min-height: 250px;'></div>` },


      // { div: `<div id='div-gpt-ad-1718763915476-0' style='min-width: 320px; min-height: 480px;'></div>` },


      { div: `<div id='div-gpt-ad-1718618187281-0' style='min-width: 300px; min-height: 250px;'></div>` },
      { div: `<div id='div-gpt-ad-1718618226621-0' style='min-width: 300px; min-height: 250px;'></div>` },
      { div: `<div id='div-gpt-ad-1718618261662-0' style='min-width: 300px; min-height: 250px;'></div>` },
    ],
    count: 0,
  },
  getters: {},
  mutations: {
    countAdd(state) {
      // console.log('before countAdd', state.count);
      state.count++;
      // console.log(' countAdd over', state.count);
    },
  },
  actions: {},
  modules: {},
});
